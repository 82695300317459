<template>
  <validation-observer
    ref="createUserForm"
    #default="{ invalid }"
  >
    <b-form
      class="create-user-form"
      @submit.prevent="()=>formActions('submit')"
    >
      <validation-provider
        #default="{ errors }"
        name="First Name"
        vid="firstName"
        rules="required|min:3"
      >
        <k-form-group
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
        >
          <template #label>
            First Name <span class="invalid-input">*</span>
          </template>
          <k-form-input
            v-model="userModel.firstName"
            type="text"
            name="user-first-name"
            placeholder="First Name"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Last Name"
        vid="lastName"
        rules="required"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
        >
          <template #label>
            Last Name <span class="invalid-input">*</span>
          </template>
          <k-form-input
            v-model="userModel.lastName"
            type="text"
            name="user-last-name"
            placeholder="Last Name"
            :state="errors.length > 0 ? false : null"
            autocomplete="off"
          />
        </k-form-group>
      </validation-provider>
      <validation-provider
        #default="{ errors }"
        name="Email Address"
        vid="email"
        rules="required|email"
      >
        <k-form-group
          class="mt-2"
          :form-invalid-content="errors[0]"
          :state="errors.length > 0 ? false : null"
        >
          <template #label>
            Email Address <span class="invalid-input">*</span>
          </template>
          <k-form-input
            v-model="userModel.email"
            type="text"
            name="user-email-address"
            placeholder="User Email Address"
            :state="errors.length > 0 ? false : null"
          />
        </k-form-group>
      </validation-provider>
      <k-form-group
        class="mt-2"
      >
        <template #label>
          Admin Type <span class="invalid-input">*</span>
        </template>
        <b-dropdown
          class="k-search-dropdown dropdown-custom-class w-100 text-capitalize"
          variant="none"
          :class="{'has-value': !!userModel.role}"
          toggle-class="drop-caret-icon"
          :text="userModel.role"
        >
          <b-dropdown-item
            v-for="role in Object.keys(ADMIN_ROLES)"
            :key="role"
            :active="userModel.role === ADMIN_ROLES[role]"
            @click="userModel.role = ADMIN_ROLES[role]"
          >
            {{ ADMIN_ROLES[role] }}
          </b-dropdown-item>
        </b-dropdown>
      </k-form-group>
      <k-form-group
        class="mt-2"
      >
        <template #label>
          Status
        </template>
        <b-dropdown
          class="k-search-dropdown dropdown-custom-class w-100 text-capitalize"
          variant="none"
          :class="{'has-value': !!userModel.status}"
          toggle-class="drop-caret-icon"
          :text="userModel.status"
        >
          <b-dropdown-item
            v-for="status in Object.keys(ADMIN_USER_STATUS)"
            :key="status"
            :active="userModel.status === ADMIN_USER_STATUS[status]"
            @click="userModel.status = ADMIN_USER_STATUS[status]"
          >
            {{ ADMIN_USER_STATUS[status] }}
          </b-dropdown-item>
        </b-dropdown>
      </k-form-group>
      <div class="d-flex flex-row mt-4">
        <k-button
          v-if="!isEditUser"
          variant="info"
          type="submit"
          block
          :disabled="invalid || loading"
        >
          CREATE ADMIN
          <feather-icon
            v-if="loading"
            icon="LoaderIcon"
          />
        </k-button>
        <k-button
          v-if="isEditUser"
          variant="info"
          type="submit"
          block
          :disabled="invalid || loading"
        >
          UPDATE ADMIN
          <feather-icon
            v-if="loading"
            icon="LoaderIcon"
          />
        </k-button>
        <k-button
          variant="outline-info"
          class="ms-4"
          block
          :disabled="loading"
          @click="()=>formActions('cancel')"
        >
          CANCEL
        </k-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import {
  apiToastErrorV2, apiToastSuccessV2,
} from '@/@core/utils/toast'
import { email, required } from '@/@core/utils/validations/validations'
import FeatherIcon from '@/components/feather-icon/FeatherIcon.vue'
import { ADMIN_USER_STATUS } from '@/constants'
import { CREATE_ADMIN_USER, UPDATE_ADMIN_USER } from '@/store/modules/admin-user.module'
import { TOGGLE_SIDEBAR } from '@/store/modules/sidebar.module'
import '@fontsource/inter'
import '@fontsource/work-sans'
import { KButton, KFormGroup, KFormInput } from '@kingpin-global/kingpin-ui'
import { constants } from '@kingpin-global/kingpin-utils-frontend'
import {
  BDropdown, BDropdownItem,
  BForm,
  VBTooltip,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

const ADMIN_ROLES = constants

export default {
  name: 'CreateUser',
  components: {
    BForm,
    KButton,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    FeatherIcon,
    KFormGroup,
    KFormInput,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    componentContent: {
      type: Object,
      default() {
        return null
      },
    },
  },
  data() {
    return {
      loading: false,
      ADMIN_ROLES,
      ADMIN_USER_STATUS,
      adminUserId: null,
      userModel: {
        firstName: '',
        lastName: '',
        email: '',
        role: ADMIN_ROLES.ADMIN,
        status: ADMIN_USER_STATUS.ACTIVE,
      },
    }
  },
  computed: {
    required() {
      return required
    },
    email() {
      return email
    },
    isEditUser() {
      return !!this.componentContent
    },
  },
  watch: {
    componentContent() {
      if (this.componentContent) {
        this.assignUser(this.componentContent)
      }
    },
  },
  created() {
    if (this.componentContent) {
      this.assignUser(this.componentContent)
    }
  },
  methods: {
    submit() {
      this.loading = true
      if (this.isEditUser) {
        const userData = this.getEditedUser(this.userModel, this.componentContent)
        if (Object.keys(userData).length === 0) {
          apiToastErrorV2('No changes made')
          this.loading = false
          return
        }
        const payload = {
          adminUserId: this.adminUserId,
          payload: userData,
        }
        this.updteAdminUser(payload)
      }
      else {
        this.createAdminUser(this.userModel)
      }
    },
    createAdminUser(payload) {
      this.$store.dispatch(CREATE_ADMIN_USER, payload)
        .then(({ data }) => {
          apiToastSuccessV2(data.message)
          this.$emit('dynamic-emits', 'submit')
        })
        .catch(err => {
          this.loading = false
          apiToastErrorV2(err)
        })
    },
    updteAdminUser(payload) {
      this.$store.dispatch(UPDATE_ADMIN_USER, payload)
        .then(({ data }) => {
          apiToastSuccessV2(data.message)
          this.$emit('dynamic-emits', 'submit')
        })
        .catch(err => {
          this.loading = false
          apiToastErrorV2(err)
        })
    },
    formActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.submit()
          break
        case 'cancel':
        default:
          this.toggleSidebar()
      }
    },
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    assignUser(user) {
      if (user) {
        this.userModel = {
          firstName: user.firstName,
          lastName: user.lastName,
          email: user.email,
          role: user.role,
          status: user.status,
        }
        this.adminUserId = user._id
      }
    },
    getEditedUser(editedUser, user) {
      const userData = {}
      for (const [key, value] of Object.entries(editedUser)) {
        if (editedUser[`${key}`] !== user[`${key}`]) {
          userData[`${key}`] = value
        }
      }
      return userData
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/variables/_variables-components.scss";
@import "../../assets/scss/kingpin-style.scss";
.create-user-form {
  padding: 0% $side-bar-form-padding;
  font-family: "Work Sans";
}
.error-message{
  left: -80px !important;
  .tooltip-inner{
    background: $error-text-color;
    color: $white;
    max-width: 250px;
  }
}
</style>

<template>
  <div>
    <div>
      <!-- Header section component -->
      <k-header-section
        header-text="Admin Users"
        :total-items-count="itemsCount"
        :current-page-count="currentPageCount"
        item-label="Admin Users"
      >
        <div class="d-flex justify-content-between inter-500-14">
          <k-button
            variant="info"
            @click="onSetCreateUser"
          >
            <kingpin-icon
              icon="PlusIcon"
              size="16"
            />
            <span class="ps-2 text-uppercase">Create New Admin</span>
          </k-button>
        </div>
      </k-header-section>

    </div>
    <div class=" table-padding">
      <!-- Filter section -->
      <div class="filter-sorting-container">
        <k-search-bar-section
          placeholder="Search for Full name, Email, Admin type..."
          hide-sort-option
          hide-filter-option
          hide-column-edit-option
          @on-search="updateSearch"
        />
        <k-sorting
          class="ms-auto"
          hide-column-edit-option
          :sort-items="tableFields"
          @update-sort="updateSort"
          @update-sort-direction="updateSortDirection"
        />
      </div>
      <!-- Table section -->
      <div
        v-if="isLoading"
        class="spinner-body"
      >
        <b-spinner />
      </div>
      <div
        v-else
        class=" poisiton-relative table-responsive"
      >
        <p-table
          :fields="tableFields"
          :items="items"
          :filter="searchText"
          :sort-by="sortBy"
          :per-page="perPage"
          :sort-desc="isDesc"
          :current-page="currentPage"
          :filter-included-fields="filterIncludedFields"
          @filtered="getFilteredItems"
        >
          <template #emptyfiltered>
            <p
              class="
              h2
              d-flex
              table-empty-message
              justify-content-center
              align-items-center
              gap-2
            "
            >
              <b-icon
                icon="exclamation-circle"
                scale="1"
              />
              <span> No Items Found </span>
            </p>
          </template>
          <template #empty>
            <p
              class="
              h2
              d-flex
              table-empty-message
              justify-content-center
              align-items-center
              gap-2
            "
            >
              <b-icon
                icon="exclamation-circle"
                scale="1"
              />
              <span> No Filtered Items </span>
            </p>
          </template>
          <template #cell(role)="data">
            <span class="text-capitalize">{{ data.item.role }}</span>
          </template>
          <template #cell(name)="data">
            <span @click="editUser(data.item)">{{ data.item.name }}</span>
          </template>
        </p-table>
      </div>
      <k-table-pagination
        :total-items="itemsCount"
        @emit-current-page="updateCurrentPage"
        @emit-per-page="updatePerPage"
      />
    </div>
  </div>
</template>

<script>
import { KSearchAndSortMixin } from '@/mixins/k-searbar-section.mixin'
import {
  FETCH_ADMIN_USERS,
} from '@/store/modules/admin-user.module'
import {
  CLEAR_STATE,
  SET_COMPONENT,
  SET_COMPONENT_CONTENT,
  SET_FORM_ACTIONS,
  SET_SUB_TITLE,
  SET_TITLE,
  TOGGLE_SIDEBAR,
} from '@/store/modules/sidebar.module'
import { getAdminUsersFields } from '@/table-fields-constants'
import {
  KButton,
  KHeaderSection,
  KSearchBarSection,
  KSorting,
  KTablePagination,
  KingpinIcon,
  PTable,
} from '@kingpin-global/kingpin-ui'
import { BIcon, BSpinner } from 'bootstrap-vue'
import HeaderColumns from '../filter/HeaderColumns.vue'
import CreateUser from './CreateUser.vue'

export default {
  name: 'UserList',
  components: {
    PTable,
    KHeaderSection,
    BIcon,
    KTablePagination,
    BSpinner,
    KSearchBarSection,
    KSorting,
    KButton,
    KingpinIcon,
  },
  mixins: [KSearchAndSortMixin],
  data() {
    return {
      fields: [],
      tableFields: [],
      filteredItems: [],
      sortBy: 'name',
      searchText: '',
      loading: true,
      selected: [],
      headerComponentContent: {
        headerColumns: this.adminUsersFields,
      },
      selectAll: false,
      itemsCount: 0,
      perPage: 10,
      currentPage: 1,
      currentPageCount: 0,
      isDesc: false,
      isLoading: false,
      items: [],
    }
  },
  computed: {
    adminUsersFields() {
      return getAdminUsersFields()
    },
    filterIncludedFields() {
      return this.tableFields.map(fields => fields.key)
    },
  },
  mounted() {
    this.initPageContent()
  },
  methods: {
    toggleSidebar() {
      this.$store.commit(TOGGLE_SIDEBAR)
    },
    onSetCreateUser() {
      this.$store.commit(CLEAR_STATE)
      this.$store.commit(SET_TITLE, 'Create New Admin')
      this.$store.commit(SET_COMPONENT, CreateUser)
      this.$store.commit(SET_FORM_ACTIONS, this.formActions)
      this.toggleSidebar()
    },
    onSetUserListHeaderColumn() {
      this.$store.commit(SET_TITLE, 'Edit Columns')
      this.$store.commit(SET_COMPONENT, HeaderColumns)
      this.$store.commit(SET_COMPONENT_CONTENT, this.headerComponentContent)
      this.$store.commit(SET_FORM_ACTIONS, this.editHeaderformActions)
      this.toggleSidebar()
    },
    saveUser() {
      // Write form submit function here ...
      this.toggleSidebar()
      this.initPageContent()
    },
    editUser(user) {
      this.$store.commit(CLEAR_STATE)
      this.$store.commit(SET_TITLE, 'Edit Admin')
      this.$store.commit(SET_SUB_TITLE, `Admin ID: ${user._id}`)
      this.$store.commit(SET_COMPONENT, CreateUser)
      this.$store.commit(SET_COMPONENT_CONTENT, user)
      this.$store.commit(SET_FORM_ACTIONS, this.formActions)
      this.toggleSidebar()
    },
    editHeaderformActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.updateHeaderColumns()
          break
        case 'cancel':
        default:
          this.toggleSidebar()
      }
    },
    updateHeaderColumns() {
      this.tableFields = this.headerComponentContent.headerColumns.filter(field => field.value)
      if (this.tableFields.length === 0) {
        this.getTableFields(this.adminUsersFields)
        this.headerComponentContent.headerColumns = this.adminUsersFields
      }
      this.toggleSidebar()
    },
    formActions(actionType) {
      switch (actionType) {
        case 'submit':
          this.saveUser()
          break
        case 'cancel':
        default:
          this.toggleSidebar()
      }
    },
    async initPageContent() {
      this.isLoading = true
      const queryParams = {}
      await this.$store.dispatch(FETCH_ADMIN_USERS, queryParams)
        .then(res => {
          this.items = res.data.data
          this.items.forEach(item => { item.name = `${item.firstName} ${item.lastName}` })
          this.itemsCount = this.items.length
          this.filteredItems = this.items
          this.getCurrentPageCount()
        })
        .finally(() => {
          this.isLoading = false
        })
      this.getTableFields(this.adminUsersFields)
      this.setTableData()
    },
    getTableFields(fields) {
      this.tableFields = fields.filter(field => field.value)
    },
    setTableData() {
      this.filteredItems = this.items
      this.headerComponentContent.headerColumns = this.adminUsersFields
      this.itemsCount = this.items.length
      this.getCurrentPageCount()
    },
    updateSort(value) {
      this.sortBy = value
    },
    updateSearch(value) {
      this.searchText = value
    },
    updateCurrentPage(value) {
      this.currentPage = value
      this.getCurrentPageCount()
    },
    updatePerPage(value) {
      this.perPage = value
      this.getCurrentPageCount()
    },
    updateSortDirection(value) {
      this.isDesc = value
    },
    onclickSelectAll(event) {
      if (event.target.checked) {
        this.selected = []
        this.selected = this.filteredItems.map(items => items._id)
        this.filteredItems.forEach(item => {
          item._rowVariant = 'border-left'
        })
      } else {
        this.selected = []
        this.filteredItems.forEach(item => {
          item._rowVariant = ''
        })
      }
    },
    getFilteredItems(items) {
      this.itemsCount = items.length
      this.filteredItems = items
      this.$emit('update-total-items', items)
      this.getCurrentPageCount()
    },
    getCurrentPageCount() {
      const totalCount = this.filteredItems.length
      const pageCount = totalCount / this.perPage
      if (this.currentPage <= pageCount) {
        this.currentPageCount = this.perPage
      }
      else {
        this.currentPageCount = Math.ceil(totalCount % this.perPage)
      }
    },
  },
}
</script>
